.beefup {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 1em 0;
  padding: 10px 20px;
}

.beefup:first-child {
  margin-top: 0;
}

.beefup__head {
  cursor: pointer;
  margin-bottom: 0;
  overflow: hidden;
  padding-right: 30px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.beefup__head::after {
  border-style: solid;
  border-width: 10px 10px 0;
  border-color: #ddd transparent transparent;
  content: '';
  position: absolute;
  right: 0;
  height: 0;
  margin-top: -5px;
  top: 50%;
  width: 0;
}

.beefup__body {
  padding: 20px 0 0;
}

.beefup.is-open > .beefup__head::after {
  border-width: 0 10px 10px;
  border-color: transparent transparent #ddd;
}
